@media (min-width: 1024px) {
  .product-iframe-section {
    position: relative;
    overflow: scroll;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .w-full#idFrameView {
    max-width: 1065px;
    min-height: 1000px;
    margin: auto;
    position: absolute;
    top: 0;
  }

  #idFrameViewCustomWidth {
    margin: auto;
    position: absolute;
    top: 0;
  }
}
